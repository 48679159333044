import store from "@/state/store";

import administrator from "./admin/administrator";
import role from "./admin/role";
import client from "./admin/client";
import subscription from "./admin/subscription";
import reportTransaction from "./admin/report-transaction";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
          next();
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/reset-password",
    name: "Reset password",
    component: () => import("../views/account/reset-password.vue"),
    meta: {
      title: "Reset Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { title: "Dashboard", authRequired: true, description: "Sorotan info paling penting" },
    component: () => import("../views/admin/dashboard/dashboard"),
  },
  
  administrator,
  role,
  client,
  subscription,
  
  {
    path: "/audit-log",
    name: "audit-log",
    meta: { title: "Audit Log", description: "The most important info highlights", authRequired: true },
    component: () => import("../views/admin/audit-log/index"),
  },
  
  reportTransaction,
  {
    path: "/setting",
    name: "setting",
    meta: { title: "Setting", description: "Home - Setting", authRequired: true },
    component: () => import("../views/admin/setting/index"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: { title: "Profile", description: "Home - Profile", authRequired: true },
    component: () => import("../views/admin/profile.vue"),
  },




  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
      description: "Sorotan info paling penting"
    },
    component: () => import("../views/admin/dashboard/dashboard"),
  },
];
